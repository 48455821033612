import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, Container, Row, Col, CardBody, CardTitle, CardText, Button, FormGroup, Input, Label } from 'reactstrap'
import Header from './header'
import Footer from './NewFooter'
import pricingNotes from '../../images/pricing-new/pricing-notes.svg'
import cross from '../../images/pricing-new/cross.svg'
import tick from '../../images/pricing-new/tick.svg'
import downArrow from '../../images/pricing-new/down-arrow.svg'
import { useHistory } from 'react-router-dom'
import clsx from 'classnames'
import request from '../../api/api'
import { pricingfeatureData, tierPricingSubPlans } from '../common/utils'

const PricingSection = () => {
   const pricingTableRef = useRef(null)
   const domhistory = useHistory()
   let initialCurrency = {
      currency_symbol: '£',
      currency: 'GBP'
   }
   const [selectedcurrency, setSelectedCurrency] = useState(initialCurrency)
   const [planList, setPlanList] = useState([])
   const [isLoading, setLoading] = useState(false)

   const getPricingList = async () => {
      try {
         setLoading(true)
         let respData = await request({
            url: '/site/tierd_pricing_list',
            method: 'POST'
         })
         if (respData.status === 1 && respData.response && respData.response.result) {
            let plandata = respData.response.result.map(item => {
               item.sub_modules = (tierPricingSubPlans.find(i => i.planId === item._id) || {}).sub_modules || []
               return item
            })
            setPlanList(plandata)
         }
      } catch (error) {
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      getPricingList()
   }, [])

   const onGetStart = () => {
      domhistory.push('/signup')
   }
   const onSelectCurrency = e => {
      let { name, value, options = null, selectedIndex = 0 } = e.target || {}
      let opts = {}
      if (name === 'currency_symbol' && options && typeof selectedIndex === 'number') {
         const selectedOption = options[selectedIndex]
         const selectedId = selectedOption.id
         opts.currency = selectedId
         opts.currency_symbol = value
      }
      setSelectedCurrency(opts)
   }

   const scrollToPricingTable = () => {
      if (!pricingTableRef.current) return
      pricingTableRef.current.scrollIntoView({ behavior: 'smooth' })
   }

   return (
      <>
         <Header></Header>
         <div className="container n-pricing">
            <div className="n-pricing-heading">
               <h1>
                  Unlock the <span>Power of Flexibility</span>
               </h1>
               <p>
                  with Our Simple <span class="underline-text">Pricing Plans</span>
               </p>
            </div>

            <div className="pricing-currency">
               <div className="position-relative form-group">
                  <label for="currency_symbol" className=""></label>
                  <select
                     name="currency_symbol"
                     id="currency_symbol"
                     // defaultValue={currency_symbol}
                     value={selectedcurrency.currency_symbol}
                     className="is-untouched is-pristine av-valid select-currency"
                     onChange={e => onSelectCurrency(e)}
                  >
                     <option value="" disabled>
                        Select Currency
                     </option>
                     <option value="£" aria-label="GBP" id="GBP">
                        GBP £
                     </option>
                     <option value="$" aria-label="USD" id="USD">
                        USD $
                     </option>
                     <option value="€" aria-label="EUR" id="EUR">
                        EUR €
                     </option>
                  </select>
               </div>
            </div>
            {isLoading ? (
               <div className="pricing_loading"> Loading...</div>
            ) : (
               <div className="pricing-flex-box">
                  {Array.isArray(planList) ? (
                     planList.map((item = {}, i) => {
                        return (
                           <PlanCard
                              {...item}
                              plandata={item}
                              scrollToPricingTable={scrollToPricingTable}
                              onGetStart={onGetStart}
                              currency={selectedcurrency.currency}
                              currency_symbol={selectedcurrency.currency_symbol}
                           />
                        )
                     })
                  ) : (
                     <></>
                  )}
                  <div className="pricing-box">
                     <span className="package-type">Enterprise</span>
                     <div className="price-tag">
                        <p className="mb-4">Lets Talk</p>
                        <p className="custom"> Custom Pricing</p>
                     </div>
                     <button className="started-btn" onClick={onGetStart}>
                        Get Started
                     </button>
                     <div className=""></div>
                     <div className="text-center">{/* <select>
                        <option>See All Features</option>
                     </select> */}</div>
                  </div>
               </div>
            )}

            <div className="price-feature-table" id="np-pricing-table" ref={pricingTableRef}>
               <h2>Compare Plans & Features</h2>
               <div class="np-pricing-table">
                  <table>
                     <thead>
                        <tr>
                           <th></th>
                           <th>Basic</th>
                           <th>Starter</th>
                           <th>Pro</th>
                           <th>Premium</th>
                        </tr>
                     </thead>

                     {pricingfeatureData.map((item, i) => {
                        return (
                           <>
                              <div className="np-topic-title">
                                 <tr>
                                    <td colSpan={12} className={item.sub_title ? 'np-package-title-black' : 'np-package-title'}>
                                       {item.title}
                                    </td>
                                 </tr>
                              </div>
                              <tbody>
                                 {item.features && item.features.length > 0
                                    ? item.features.map((fea = {}, index) => {
                                         return (
                                            <tr className={index === 0 ? 'border-b' : ''}>
                                               <td class="np-feature-title">{fea.name}</td>
                                               {fea.plans && fea.plans.map(plan => <AccessIncluded type={plan} />)}
                                            </tr>
                                         )
                                      })
                                    : null}
                              </tbody>
                           </>
                        )
                     })}
                     <tbody>
                        <tr className="border-b">
                           <td class="np-feature-title"></td>
                           {Array.isArray(planList) ? (
                              planList.map((item = {}, i) => {
                                 return (
                                    <PlanCard
                                       {...item}
                                       plandata={item}
                                       scrollToPricingTable={scrollToPricingTable}
                                       onGetStart={onGetStart}
                                       currency={selectedcurrency.currency}
                                       currency_symbol={selectedcurrency.currency_symbol}
                                       isBottomCard
                                    />
                                 )
                              })
                           ) : (
                              <></>
                           )}
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <Footer></Footer>
      </>
   )
}

const df = () => {}

const PlanCard = ({
   scrollToPricingTable = df,
   onGetStart = df,
   employee_per_price = false,
   min_employee_status = false,
   min_employee = 10,
   // price = '',
   currency = 'GBP',
   _id: selectedId,
   popular = false,
   name: plan_name = '',
   currency_symbol = '',
   sub_modules = [],
   productId = '',
   plandata = {},
   cartdata = {},
   activeEmployee = 0,
   isBottomCard = false
}) => {
   let getkey = currency.toLowerCase()
   // let pricedetailskey = `price_details_${getkey}`
   let pricekey = `price_${getkey}`
   let price = plandata[pricekey] || 0
   return isBottomCard ? (
      <>
         <td>
            <div className="table-pricing-box">
               <span className="package-type">{plan_name}</span>
               <div className="price-tag">
                  <p>
                     {price > 0 ? (
                        <>
                           {currency_symbol}
                           {price}
                           {employee_per_price && <span> / Active Employee</span>}
                        </>
                     ) : (
                        'Free'
                     )}
                  </p>
                  {min_employee_status && <p className="blue-quote"> Minimum {min_employee} Employees</p>}
               </div>
               <button className="started-btn" onClick={onGetStart}>
                  Get Started
               </button>
            </div>
         </td>
      </>
   ) : (
      <div className={clsx(`pricing-box`, { 'np-popular-box': popular })}>
         {popular && <p className="np-popular ">Popular</p>}

         <span className="package-type">{plan_name}</span>

         <div className="price-tag">
            <p className='price_num'>
               {price > 0 ? (
                  <>
                     {currency_symbol}
                     {price}
                     {employee_per_price && <span> / Active Employee</span>}
                  </>
               ) : (
                  'Free'
               )}
            </p>
            {min_employee_status && <p className="blue-quote"> Minimum {min_employee} Employees</p>}
         </div>

         <button className="started-btn" onClick={onGetStart}>
            Get Started
         </button>

         <div className="pricing-features">
            {Array.isArray(sub_modules) ? (
               sub_modules.map((item, i) => {
                  return (
                     <div key={i} className="text-overflow">
                        <img src={pricingNotes} alt="" />
                        <span> {item.module_name || ''}</span>
                     </div>
                  )
               })
            ) : (
               <></>
            )}
         </div>

         <div className="see-feature" onClick={scrollToPricingTable}>
            <p>See All Features</p>
            <div>
               <img src={downArrow} alt="" width="40" height="18" />
            </div>
         </div>
      </div>
   )
}

const AccessIncluded = ({ type = '' }) => {
   return (
      <>
         {['cross', 'tick'].includes(type) ? (
            <td>
               <span class={clsx(`np-icon ${'cross' === type ? 'red' : 'green'}`)}>
                  <img src={'cross' === type ? cross : tick} />
               </span>
            </td>
         ) : (
            <td>{type}</td>
         )}
      </>
   )
}

// const

export default PricingSection
